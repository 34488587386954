
// eslint-disable-next-line no-unused-vars
import { defineComponent, ref, reactive, PropType } from 'vue';
// eslint-disable-next-line no-unused-vars
import { AirCleaningDevice } from '@/constants/delos-products';

import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/outline';

export default defineComponent({
  components: {
    ChevronDownIcon,
    ChevronRightIcon
  },
  props: {
    airCleaningDevice: {
      type: Object as PropType<AirCleaningDevice>,
      required: true
    }
  },
  setup(props) {
    const details = reactive([
      { heading: 'Fan speeds', content: props.airCleaningDevice.fanSpeeds },
      { heading: 'Dimensions', content: `${props.airCleaningDevice.dimensions.width}" (W) x ${props.airCleaningDevice.dimensions.depth}" (D) x ${props.airCleaningDevice.dimensions.height}" (H)` },
      { heading: 'Weight', content: `${props.airCleaningDevice.weight} lbs.` },
    ]);

    return {
      details,
    };
  }
});
