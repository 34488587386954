<template>
  <div class="flex bg-gray-100 border-2 rounded-lg">
    <div class="flex items-center w-60 bg-gradient-to-b from-cyan-800 to-cyan-900 rounded-l-lg">
      <img :src="require(`@/assets/${airCleaningDevice.imgSrc}`)" :alt="'Image of ' + airCleaningDevice.title">
    </div>
    <div class="flex flex-col w-full justify-between pt-8 pb-6 px-8">
      <h1 class="text-2xl font-bold mb-3 grow">{{ airCleaningDevice.title }}</h1>
      <div class="text-base">
        <p v-for="detailItem in details" :key="detailItem.heading">
          <span class="font-bold">{{ detailItem.heading }}:</span>
          {{ detailItem.content }}
        </p>
      </div>
      <div class="flex flex-wrap gap-4 mt-4">
        <a :href='airCleaningDevice.canadianAffiliateLink'
          class="inline-flex items-center align-middle px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 print:hidden">
          <img src="@/assets/svg/canadian-flag-icon.svg" alt="Canadian flag" />
          <span class="ml-2 whitespace-nowrap">Get a quote</span>
        </a>
        <a :href='airCleaningDevice.americanAffiliateLink'
          class="inline-flex items-center align-middle px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 print:hidden">
          <img src="@/assets/svg/american-flag-icon.svg" alt="American flag" />
          <span class="ml-2 whitespace-nowrap">Get a quote</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line no-unused-vars
import { defineComponent, ref, reactive, PropType } from 'vue';
// eslint-disable-next-line no-unused-vars
import { AirCleaningDevice } from '@/constants/delos-products';

import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/outline';

export default defineComponent({
  components: {
    ChevronDownIcon,
    ChevronRightIcon
  },
  props: {
    airCleaningDevice: {
      type: Object as PropType<AirCleaningDevice>,
      required: true
    }
  },
  setup(props) {
    const details = reactive([
      { heading: 'Fan speeds', content: props.airCleaningDevice.fanSpeeds },
      { heading: 'Dimensions', content: `${props.airCleaningDevice.dimensions.width}" (W) x ${props.airCleaningDevice.dimensions.depth}" (D) x ${props.airCleaningDevice.dimensions.height}" (H)` },
      { heading: 'Weight', content: `${props.airCleaningDevice.weight} lbs.` },
    ]);

    return {
      details,
    };
  }
});
</script>

<style scoped>
</style>
